import React, {
  Fragment,
  FunctionComponent,
  ReactElement,
  SyntheticEvent,
} from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Notifications from "../notifications/Notifications";
import CaretDown from "../icons/CaretDown";
import UserSettingsMenu from "./UserSettingsMenu";

import "../../styles/components/common/PageHeader.scss";
import { useGetLoginInfoQuery } from "../../api/homecaseMvcApi";

export default function PageHeader({
  title,
  skeleton,
  actionComponent,
  searchComponent,
  onSearch,
  backTo,
  onChange,
  placeholder,
  showPageHeaderActions = true,
  actionComponentProps,
}: Props): ReactElement {
  const ActionComponent = actionComponent || undefined;
  const SearchComponent = searchComponent || undefined;
  const { data: isLoggedIn } = useGetLoginInfoQuery();
  return (
    <Fragment>
      {backTo && (
        <Link to={backTo.to} className="page-header-back">
          <CaretDown width={16} height={16} /> {backTo.label}
        </Link>
      )}
      <header className="page-header">
        {backTo && (
          <Link to={backTo.to} className="page-header-back-mobile">
            <CaretDown height={18} width={18} />
          </Link>
        )}
        <h1 className="page-header-headline">
          {skeleton && !title ? <Skeleton /> : title}
        </h1>
        {SearchComponent && (
          <SearchComponent
            onChange={onSearch}
            placeholder={title}
            collapsableOnMobile
          />
        )}
        {showPageHeaderActions && (
          <div className="page-header-actions">
            {ActionComponent && (
              <ActionComponent
                title={title}
                onChange={onChange}
                placeholder={placeholder}
                {...actionComponentProps}
              />
            )}
            <Notifications />
            {isLoggedIn && <UserSettingsMenu />}
          </div>
        )}
      </header>
    </Fragment>
  );
}

type Props = {
  title?: string;
  skeleton?: boolean;
  actionComponent?: FunctionComponent<ActionComponentProps>;
  searchComponent?: FunctionComponent<SearchComponentProps>;
  backTo?: { to: string; label: string };
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onSearch?: (e: SyntheticEvent<HTMLInputElement>) => void;
  placeholder?: string;
  showPageHeaderActions?: boolean;
  actionComponentProps?: ActionComponentCustomProps;
};

type SearchComponentProps = {
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  placeholder?: string;
  collapsableOnMobile: boolean;
};

export type ActionComponentProps = {
  title: string | undefined;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & any;

type ActionComponentCustomProps = {
  title?: string;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & any;
