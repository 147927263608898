import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";
import PostList from "../bulletinBoard/PostList";

import "../../styles/components/pages/DashboardPage.scss";
import Contacts from "../dashboard/Contacts";
import Documents from "../dashboard/Documents";
import UpcomingDates from "../dashboard/UpcomingDates";
import LightboxProvider from "../common/lightbox/LightboxProvider";
import Lightbox from "../common/lightbox/Lightbox";

export default function DashboardPage(): ReactElement {
  return (
    <div className="dashboard">
      <Helmet>
        <title>Schwarzes Brett - Homecase</title>
      </Helmet>
      <PageHeader title="Schwarzes Brett" />
      <LightboxProvider options={{ showMeta: true }}>
        <div className="dashboard-container">
          <div className="dashboard-container-left">
            <PostList />
          </div>
          <div className="dashboard-container-right">
            <Contacts />
            <Documents />
            <UpcomingDates />
          </div>
        </div>
        <Lightbox />
      </LightboxProvider>
    </div>
  );
}
