import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  AttendMeeting,
  MeetingInvitation,
  MeetingRequest,
  Participant,
  ParticipantAttendanceRequest,
  ParticipantLocallyAttendanceRequest,
  ParticipantRepresentativeRequest,
  ParticipantRequest,
  ParticipantsRequest,
  ParticipantVotingEligibilityRequest,
} from "../types";
import { PARTICIPANTS_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";

export const participantsApi = createApi({
  reducerPath: PARTICIPANTS_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ["Participant", "AttendMeeting", "Invitation"],
  endpoints: (build) => ({
    getParticipants: build.query<Participant[], ParticipantsRequest>({
      query: ({ customerToken, meetingId, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/participants`,
      providesTags: ["Participant"],
    }),
    getAttendance: build.query<AttendMeeting, ParticipantsRequest>({
      query: ({ customerToken, meetingId, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/myAttendance`,
        method: "HEAD",
        responseHandler: (response) =>
          Promise.resolve({
            meetingId,
            attend: response.status === 204,
            watch: response.status === 409,
          }),
        validateStatus: () => true,
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: "AttendMeeting" as const,
                id: result.meetingId,
              },
              "AttendMeeting",
            ]
          : ["AttendMeeting"],
    }),
    getInvitationState: build.query<MeetingInvitation, ParticipantsRequest>({
      query: ({ customerToken, meetingId, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/myInvitation`,
      }),
      providesTags: () => ["Invitation"],
    }),
    attend: build.mutation<AttendMeeting, ParticipantsRequest>({
      query: ({ customerToken, meetingId, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/attend`,
        method: "POST",
      }),
      invalidatesTags: ["AttendMeeting"],
    }),
    leave: build.mutation<AttendMeeting, ParticipantAttendanceRequest>({
      query: ({ customerToken, meetingId, facilityObjectId, attendance }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/leave`,
        method: "POST",
        body: { attendance },
      }),
      invalidatesTags: ["AttendMeeting"],
    }),

    setLocallyAttendance: build.mutation<
      void,
      ParticipantLocallyAttendanceRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        attendance,
        participantId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/participants/${participantId}/setAttendance`,
        method: "POST",
        body: { attendance },
      }),
      invalidatesTags: ["Participant"],
    }),
    setVotingEligibility: build.mutation<
      void,
      ParticipantVotingEligibilityRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        participantId,
        delegationType,
        delegateId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/participants/${participantId}/votingEligibility/delegate`,
        method: "POST",
        body: { delegationType, delegateId },
      }),
      invalidatesTags: ["Participant"],
    }),

    setVotingEligibilityForParticipants: build.mutation<
      void,
      ParticipantVotingEligibilityRequest
    >({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        delegationType,
        delegateId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/votingEligibility/delegate`,
        method: "POST",
        body: { delegationType, delegateId },
      }),
      invalidatesTags: ["Participant"],
    }),

    getMyRelatedParticipants: build.query<Participant[], ParticipantsRequest>({
      query: ({ customerToken, meetingId, facilityObjectId }) =>
        `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/myRelatedParticipants`,
      providesTags: ["Participant"],
    }),

    retainVotingEligibility: build.mutation<void, ParticipantRequest>({
      query: ({
        customerToken,
        meetingId,
        facilityObjectId,
        participantId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/participants/${participantId}/votingEligibility/retain`,
        method: "POST",
      }),
      invalidatesTags: ["Participant"],
    }),

    retainVotingEligibilityForParticipant: build.mutation<void, MeetingRequest>(
      {
        query: ({ customerToken, meetingId, facilityObjectId }) => ({
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/votingEligibility/retain`,
          method: "POST",
        }),
        invalidatesTags: ["Participant"],
      },
    ),

    setParticipantRepresentative: build.mutation<
      void,
      ParticipantRepresentativeRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        meetingId,
        participantId,
        participantRepresentativeId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/meetings/${meetingId}/participants/${participantId}/setRepresentative/${participantRepresentativeId}`,
        method: "POST",
      }),
      invalidatesTags: ["Participant"],
    }),
  }),
});

export const {
  useGetParticipantsQuery,
  useGetAttendanceQuery,
  useGetInvitationStateQuery,
  useAttendMutation,
  useLeaveMutation,
  useSetLocallyAttendanceMutation,
  useSetVotingEligibilityMutation,
  useRetainVotingEligibilityMutation,
  useSetVotingEligibilityForParticipantsMutation,
  useGetMyRelatedParticipantsQuery,
  useRetainVotingEligibilityForParticipantMutation,
  useSetParticipantRepresentativeMutation,
} = participantsApi;
