import { Field, Form } from "react-final-form";
import classNames from "classnames";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { MutableState } from "final-form";

import TextArea from "../common/form/TextArea";
import ProfilePicture from "../common/ProfilePicture";
import Button from "../common/Button";
import FilePreview from "../common/form/FilePreview";
import FileInput from "../common/form/FileInput";
import SettingsIcon from "../icons/SettingsIcon";
import Send from "../icons/Send";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import CreatePostSelectObjects from "./CreatePostSelectObjects";
import useFetchUserProfile from "../../hooks/useFetchUserProfile";
import {
  validateFileFirstBytes,
  validateFiles,
  validateFileType,
} from "../../lib/formValidate";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useCreatePost from "../../hooks/bulletinBoard/useCreatePost";
import ErrorDialog from "../common/dialog/ErrorDialog";
import CloseIcon from "../icons/CloseIcon";
import IconButton from "../common/IconButton";

import "../../styles/components/bulletinBoard/CreatePost.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resetMultiPost = (args: any[], state: MutableState<any>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state.formState.values.facilityObjectIds = undefined;
};

export default function CreatePost({ multiPost }: Props): JSX.Element {
  const ref = useRef(null);
  const [multiPostOpen, setMultiPostOpen] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const user = useFetchUserProfile();
  const { save, isLoading, isError, error } = useCreatePost();

  const handleMultiPostOpen = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setMultiPostOpen(true);
  }, []);

  const handleMultiPostConfirm = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setMultiPostOpen(false);
    setHasFocus(true);
  }, []);

  const handleFocus = useCallback(() => setHasFocus(true), []);

  return (
    <Form
      onSubmit={save}
      mutators={{ resetMultiPost }}
      render={({ handleSubmit, dirty, form, submitSucceeded, values }) => {
        const [lastMultiPostValues, setLastMultiPostValues] = useState<
          number[] | undefined
        >(undefined);

        useEffect(() => {
          if (multiPostOpen) {
            setLastMultiPostValues(values.facilityObjectIds);
          }
        }, [multiPostOpen]);

        useEffect(() => {
          form.reset();
          setHasFocus(false);
        }, [submitSucceeded]);

        const handleMultiPostCancel = useCallback(
          (event: SyntheticEvent) => {
            event.preventDefault();
            event.stopPropagation();
            form
              .getFieldState("facilityObjectIds")
              ?.change(lastMultiPostValues);
            setMultiPostOpen(false);
          },
          [lastMultiPostValues],
        );

        const handleMultiPostClose = useCallback((event: SyntheticEvent) => {
          handleMultiPostConfirm(event);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          form.mutators.resetMultiPost();
        }, []);

        const handleBlur = useCallback(() => {
          !multiPostOpen && !dirty && setHasFocus(false);
        }, [multiPostOpen, dirty]);

        useOnClickOutside(ref, handleBlur);

        const { facilityObjectIds } = form.getState().values;
        const selectedObjectCount = facilityObjectIds?.length;
        const hasMultiPost = selectedObjectCount || 0 > 0;

        const multiPostTitle = (
          <span className="bulletin-board-post-create-post-multi-title">
            <span>In mehreren online Objekten posten</span>
            <IconButton icon={CloseIcon} onClick={handleMultiPostCancel} />
          </span>
        );
        return (
          <form
            onSubmit={handleSubmit}
            className="bulletin-board-post-create-post"
            data-testid="create-post"
          >
            <div className="bulletin-board-post-create-post-user">
              <ProfilePicture
                alt={user.firstName}
                userSid={user.sid}
                width={32}
                height={32}
                rounded
              />
            </div>
            <div
              className="bulletin-board-post-create-post-form"
              onFocus={handleFocus}
              ref={ref}
            >
              <div
                className={classNames(
                  "bulletin-board-post-create-post-fields",
                  {
                    hasFocus,
                  },
                )}
                data-testid="create-post-fields"
              >
                <Field
                  name="post"
                  component={TextArea}
                  placeholder="Beitrag erstellen..."
                  rows={hasFocus ? 3 : 1}
                  className="bulletin-board-post-create-post-field"
                  maxLength={2000}
                />

                <div className="bulletin-board-post-create-post-upload">
                  <Field
                    component={FilePreview}
                    name="attachments"
                    subscription={{ error: true, value: true, touched: true }}
                  />
                  <Field
                    component={FileInput}
                    name="attachments"
                    width={32}
                    height={32}
                    validate={validateFiles(
                      validateFileType(),
                      validateFileFirstBytes(),
                    )}
                  />
                </div>
              </div>
              <div className="bulletin-board-post-create-post-actions">
                {hasMultiPost && (
                  <span className="selected-objects">
                    {selectedObjectCount} Objekte ausgewählt
                  </span>
                )}
                {multiPost && dirty && (
                  <Button
                    leadingIcon={SettingsIcon}
                    grey
                    disabled={isLoading}
                    label="Mehrere Objekte auswählen"
                    onClick={handleMultiPostOpen}
                  />
                )}
                {dirty && (
                  <Button
                    leadingIcon={Send}
                    lightblue
                    disabled={!dirty || isLoading}
                    label="Beitrag posten"
                  />
                )}
              </div>
            </div>
            {multiPostOpen && (
              <ConfirmDialog
                description={
                  <CreatePostSelectObjects
                    selectedObjectCount={selectedObjectCount}
                  />
                }
                title={multiPostTitle}
                confirmLabel="Übernehmen"
                cancelLabel="Zurücksetzen"
                onCancel={handleMultiPostClose}
                onConfirm={handleMultiPostConfirm}
              />
            )}
            {isError && (
              <ErrorDialog
                description="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
                title="Es ist ein Fehler aufgetreten!"
                onRetry={handleSubmit}
                error={error}
              />
            )}
          </form>
        );
      }}
    />
  );
}

type Props = {
  multiPost?: boolean;
};
