import { PREVIEW_COMPONENTS } from "../../lib/messages";
import OtherFile from "./files/OtherFile";
import classNames from "classnames";
import { UploadState } from "../../enums";
import LoadingSpinner from "../icons/LoadingSpinner";

import Checkmark from "../icons/Checkmark";
import CloseIcon from "../icons/CloseIcon";
import { useCallback } from "react";
import useUploadFiles from "../../hooks/useUploadFiles";

import "../../styles/components/common/FileUploadPreview.scss";

export default function FileUploadPreview({
  referenceId,
  onClick,
  showName,
  previewWidth = 150,
  previewHeight = 150,
}: Props): JSX.Element {
  const uploadFiles = useUploadFiles(referenceId);

  const onClickHandler = useCallback((file: File) => {
    onClick && onClick(file);
  }, []);

  const uploadCompleted =
    uploadFiles.filter(
      ({ uploadState }) => uploadState === UploadState.Completed,
    ).length === uploadFiles.length;

  return (
    <div
      className={classNames("file-upload-preview", {
        "file-upload-preview-complete": uploadCompleted,
      })}
    >
      {uploadFiles.map(({ file, uploadState, error }, key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const PreviewComponent = PREVIEW_COMPONENTS[file.type] || OtherFile;
        const className = classNames({
          "file-upload-preview-item": true,
          "file-upload-preview-item-complete":
            uploadState === UploadState.Completed,
          "file-upload-preview-item-error": uploadState === UploadState.Failure,
          "file-upload-preview-item-in-progress":
            uploadState === UploadState.InProgress,
        });
        return (
          <div
            className={className}
            key={key}
            data-testid="file-upload-preview-item"
            onClick={onClickHandler.bind(null, file)}
          >
            <PreviewComponent
              width={previewWidth}
              height={previewHeight}
              file={file}
            />
            {uploadState === UploadState.Failure && (
              <div className="file-upload-preview-error">{error?.data}</div>
            )}
            <div className="file-upload-preview-item-state">
              {uploadState === UploadState.InProgress && (
                <LoadingSpinner width={32} height={32} />
              )}
              {uploadState === UploadState.Completed && (
                <Checkmark
                  width={32}
                  height={32}
                  className="file-upload-preview-item-state-complete"
                />
              )}
              {uploadState === UploadState.Failure && (
                <CloseIcon
                  width={32}
                  height={32}
                  className="file-upload-preview-item-state-error"
                />
              )}
            </div>
            {file && showName && (
              <span className="file-upload-preview-item-name" title={file.name}>
                {file.name}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}

type Props = {
  showName?: boolean;
  referenceId: string | number;
  onClick?: (file: File) => void;
  previewWidth?: number;
  previewHeight?: number;
};
