import React, { Fragment, ReactElement, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import PageHeader from "../../common/PageHeader";
import { useGetMeetingsQuery } from "../../../api/meetingApi";
import { getMeetingTitle } from "../../../lib/meeting";
import MeetingControl from "../../meetings/MeetingControl";
import MeetingAttend from "../../meetings/MeetingAttend";
import PropositionVotingStateChangedDialog from "../../meetings/proposition/PropositionVotingStateChangedDialog";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import { SynchronisationMethod, UserRoles } from "../../../enums";

import { useSynchronisation } from "../../../hooks/useSynchronisation";
import "../../../styles/components/pages/meetings/IndexPage.scss";
import MeetingStateChangedDialog from "../../meetings/MeetingStateChangedDialog";

export default function IndexPage(): ReactElement {
  const {
    meetingId = "",
    customerToken = "",
    facilityObjectId = "",
  } = useParams();
  const { data = [], refetch } = useGetMeetingsQuery({
    customerToken,
    facilityObjectId,
  });
  const { connection, connect, disconnect, switchChannel } =
    useSynchronisation();
  const customerRoles = useCustomerRoles();
  const meeting = data.find(({ id }) => id === meetingId);
  const title = meeting ? getMeetingTitle(meeting) : "Eigentümerversammlungen";

  useEffect(() => {
    connect();

    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    switchChannel();
  }, [meetingId]);

  useEffect(() => {
    if (connection) {
      connection.on(SynchronisationMethod.MeetingStateChanged, refetch);
    }

    return () => {
      if (connection) {
        connection.off(SynchronisationMethod.MeetingStateChanged);
      }
    };
  }, [connection]);

  const ActionComponent = customerRoles.includes(UserRoles.Employee)
    ? MeetingControl
    : MeetingAttend;

  const backTo = !!meetingId
    ? { to: "./", label: "Eigentümerversammlungen" }
    : undefined;
  return (
    <div className="etv">
      <Helmet>
        <title>{title} - Homecase</title>
      </Helmet>
      <PageHeader
        title={title}
        actionComponent={ActionComponent}
        backTo={backTo}
      />
      <Fragment>
        <PropositionVotingStateChangedDialog />
        <MeetingStateChangedDialog />
      </Fragment>
      <Outlet context={[title, meeting]} />
    </div>
  );
}
