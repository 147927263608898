import { Helmet } from "react-helmet";
import useBrandingSettings from "../../hooks/useBrandingSettings";
import { hexToRGBA } from "../../lib";

export default function Branding(): JSX.Element {
  const brandingSettings = useBrandingSettings();

  return (
    <Helmet>
      {brandingSettings && (
        <style type="text/css" data-testid="branding">{`:root { 
          --navigation-color-active: ${brandingSettings.primaryBrandingColor};
          --navigation-background-mobile: ${
            brandingSettings.primaryBrandingColor
          };
          --primary-color: ${brandingSettings.primaryBrandingColor};
          --button-color: ${brandingSettings.primaryBrandingColorDarker};
          --button-background-lightblue: ${
            brandingSettings.primaryBrandingColor
          };
          --navigation-background-active: ${hexToRGBA(
            brandingSettings.primaryBrandingColor,
            0.2,
          )};  
         --responsibility-name-color: ${hexToRGBA(
           brandingSettings.primaryBrandingColor,
           0.2,
         )};
          }`}</style>
      )}
    </Helmet>
  );
}
