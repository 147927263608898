import React from "react";
import { Helmet } from "react-helmet";
import PageHeader from "../common/PageHeader";

import "../../styles/components/pages/DataSecurityPage.scss";

export default function DataSecurityPage(): JSX.Element {
  const backTo = {
    to: "/",
    label: "Zurück zu HOMECASE",
  };

  return (
    <div className="data-security-page-container">
      <Helmet>
        <title> Datenschutz - Homecase</title>
      </Helmet>
      <PageHeader title="Datenschutz" backTo={backTo} />
      <div className="data-security-page-container-info">
        <span className="data-security-page-container-info-title">
          DATENSCHUTZHINWEISE HOMECASE
        </span>
        <p>
          Vielen Dank für Ihr Interesse an unserer Plattform HOMECASE
          (nachstehend „Plattform“). Der Schutz personenbezogener Daten der
          Nutzer unserer Plattform ist uns ein besonderes Anliegen. Wir
          respektieren Ihre Privatsphäre. Daher erheben und verarbeiten wir Ihre
          personenbezogenen Daten ausschließlich in Übereinstimmung mit den
          einschlägigen gesetzlichen Bestimmungen.
        </p>
        <h3>1. Verantwortlicher und Datenschutzbeauftragter</h3>
        <div className="data-security-page-container-info-text">
          <p>
            1.1 Der Verantwortliche im Sinne der EU-Datenschutzgrundverordnung
            (Art. 4 Nr. 7 DSGVO) ist die DOMUS Software AG, Otto-Hahn-Straße 4,
            85521 Riemerling (Hohenbrunn), Telefon +49.89.66086-0, Fax
            +49.89.66086-190, E-Mail
            <a href={`mailto:homecase@domus-software.de`}>
              homecase@domus-software.de
            </a>{" "}
            (nachfolgend „DOMUS“, „wir“, „unser“, „uns“ etc.).
          </p>
          <p>
            1.2 Der Schutz Ihrer persönlichen Daten hat für die DOMUS einen
            hohen Stellenwert. Um dieser Bedeutung Ausdruck zu verleihen, haben
            wir ein auf Datenschutz und Datensicherheit spezialisiertes
            Beratungsunternehmen mit der Übernahme dieser zentralen Themen
            beauftragt. Auch unser Datenschutzbeauftragter kommt aus diesem sehr
            erfahrenen Expertenkreis. Wir werden beraten durch: MAGELLAN
            Rechtsanwälte, Brienner Straße 11, 80333 München /{" "}
            <a href="https://www.magellan-datenschutz.de" target="_blank">
              www.magellan-datenschutz.de
            </a>
            .
          </p>
          <p>
            Bitte wenden Sie sich in allen Fragen rund um das Thema Datenschutz
            und Datensicherheit bei uns direkt an unseren
            Datenschutzbeauftragten von MAGELLAN Rechtsanwälte: E-Mail:{" "}
            <a href={`mailto:datenschutz_domus@magellan-legal.de`}>
              datenschutz_domus@magellan-legal.de
            </a>{" "}
            / Tel.: 089-660 860
          </p>
        </div>
        <h3>2. Personenbezogene Daten</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Personenbezogene Daten sind sämtliche Informationen, die Ihnen
            individuell zugeordnet werden können. Das sind z.B. Ihr Name, Ihre
            Anschrift, Ihr Geburtsdatum, Ihre E-Mail-Adresse und Ihre
            Telefonnummer. Nicht personenbezogene Daten sind dagegen
            Informationen allgemeiner Art, mit deren Hilfe Ihre Identität nicht
            festgestellt werden kann. Das sind z.B. die Anzahl der Nutzer der
            Plattform.
          </p>
        </div>
        <h3>
          3. Welche Arten von personenbezogenen Daten wir erheben und
          verarbeiten
        </h3>
        <div className="data-security-page-container-info-text">
          <h4>3.1 Webserver-Protokolle (inkl. der IP-Adresse)</h4>
          <p>
            Wenn Sie unsere Plattform besuchen, erfasst unser Webserver aus
            technischer Notwendigkeit automatisch Ihre IP-Adresse, das Datum und
            die Uhrzeit, zu denen Sie auf der Plattform sind, die von Ihnen auf
            Plattform besuchten Seiten, die Internetseite, auf der Sie zuvor
            waren, den von Ihnen verwendeten Browser (z.B. Mozilla Firefox,
            Google Chrome etc.), das von Ihnen verwendete Betriebssystem (z.B.
            Windows 10) sowie den Domainnamen und die Adresse Ihres
            Internet-Providers (z.B. Deutsche Telekom).
          </p>
          <p>
            Eine Auswertung dieser Informationen zu statistischen bzw.
            Marktforschungszwecken erfolgt ausschließlich in anonymisierter
            Form.
          </p>
          <p>
            Außerdem können wir die vom Webserver erfassten Informationen im
            Falle eines Systemmissbrauchs in Zusammenarbeit mit Ihrem
            Internet-Provider und/oder Behörden vor Ort verarbeiten, um den
            Urheber dieses Missbrauchs zu ermitteln. Rechtsgrundlage hierfür
            sind unsere berechtigten Interessen (Art. 6 Abs. 1 Buchst. f DSGVO),
            die darin bestehen, die Integrität der Plattform, unseres Systems
            und unserer Nutzer zu schützen.
          </p>
          <h4>
            3.2 Personenbezogene Daten, die Sie uns im Rahmen der Nutzung
            unserer Dienste auf der Plattform bereitstellen
          </h4>
          <p>
            Ansonsten erheben und verarbeiten wir personenbezogene Daten von
            Ihnen nur dann, wenn Sie uns diese freiwillig zur Verfügung gestellt
            haben, um die Plattform bereitzustellen, zu betreiben und zu
            administrieren sowie Ihnen unsere auf der Plattform vorgehaltenen
            Dienste bereitzustellen. Dabei handelt es sich im Grundsatz um die
            folgenden personenbezogenen Daten und Verarbeitungsszenarien:
          </p>
          <h4>a. Nutzerkonto und damit zusammenhängende Dienste</h4>
          <p>
            Für die Nutzung der Dienste auf der Plattform ist die Erstellung
            eines Nutzerkontos erforderlich. Hierfür benötigen wir die Angabe
            Ihres Namens, Ihrer E-Mail-Adresse sowie der Ihnen zur Freischaltung
            bereitgestellte Registrierungscode. Optional können Sie ferner Ihr
            Geschlecht, Ihr Geburtsdatum und Ihre Biografie angeben sowie ein
            Foto bereitstellen.
          </p>
          <p>
            Im Rahmen Ihrer Nutzung des Dienstes „Meine Nachrichten“, der einen
            Kommunikationskanal zwischen Ihnen und anderen Nutzern der Plattform
            (Hausverwalter, Eigentümer, Mieter und/oder Dienstleister) zur
            Abwicklung alltäglicher Aufgaben im Kontext mit der
            Immobilienverwaltung (z.B. administrative Angelegenheiten und
            Schadensregulierung etc.) darstellt, verarbeiten wir Ihren Namen
            sowie die von Ihnen im Zusammenhang mit Ihrem Anliegen
            bereitgestellten Inhalte (Texte, Fotos, Dokumente etc.), um die
            entsprechende Kommunikation abzuwickeln. Wenn Sie diesen Dienst
            benutzen, sollten Sie sich ferner darüber bewusst sein, dass
            sämtliche in Ihrer Kommunikation enthaltenen personenbezogenen
            Daten, die z.B. in Texten, Fotos und Dokumenten etc. enthalten sind,
            von sämtlichen in den Kommunikationsvorgang eingebundenen Nutzern
            der Plattform (Hausverwalter, Eigentümer, Mieter und/oder
            Dienstleister) einsehbar sind. Dies dient insoweit ebenfalls dem
            Zweck Ihr Anliegen, dass Sie über diese Informationen zum Ausdruck
            bringen, abzuwickeln.
          </p>
          <p>
            Im Rahmen Ihrer Nutzung des Dienstes „Schwarzes Brett“, das quasi
            ein digitales Pendant zu einem Schwarzen Brett in einem
            Immobilienobjekt darstellt, verarbeiten wir Ihren Namen. Wenn Sie
            diesen Dienst nutzen, sollten Sie sich ferner darüber bewusst sein,
            dass Ihr Nutzer-Profil sowie sämtliche auf dem Schwarzen Brett
            gepostete personenbezogene Daten, die z.B. in Texten, Fotos und
            Dokumenten etc. enthalten sind, von sämtlichen anderen Nutzern der
            Plattform (Hausverwalter, Eigentümer, Mieter und/oder
            Dienstleister), die auf das entsprechende Immobilienobjekt zugreifen
            können, einsehbar sind. Ferner verarbeiten wir Ihren Namen und Ihre
            E-Mail-Adresse, um Ihnen Informationen zur Nutzung der Plattform
            sowie zu etwaigen Änderungen der Plattform zukommen zu lassen. Sie
            können dieser Verarbeitung jederzeit widersprechen und den Erhalt
            derartiger Mitteilungen deaktivieren, indem Sie uns eine
            entsprechende Anfrage per E-Mail an
            <a href={`mailto:homecase@domus-software.de`}>
              homecase@domus-software.de
            </a>{" "}
            schicken.
          </p>
          <p>
            Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten
            im Rahmen des Nutzerkontos und der in dieser Ziff. 3.2 Buchst. a
            beschriebenen Dienste ist die Erfüllung unserer vertraglichen
            Pflichten (Art. 6 Abs. 1 Buchst. b DSGVO).
          </p>
          <h4>b. Sonstige Anfragen</h4>
          <p>
            Bei sonstigen Anfragen über unseren Feedbackbereich ist die Angabe
            Ihres Namens, Ihrer E-Mail-Adresse sowie Ihres Anliegens
            erforderlich, um Ihre Anfrage zu bearbeiten und zu beantworten.
            Rechtsgrundlage hierfür ist die Erfüllung unserer vertraglichen
            Pflichten (Art. 6 Abs. 1 Buchst. b DSGVO).
          </p>
        </div>
        <h3>4. Weitergabe von personenbezogenen Daten</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Wir geben keine personenbezogenen Daten an Dritte weiter, es sei
            denn dies ist zur Vertragserfüllung erforderlich, sonst aufgrund
            einschlägiger gesetzlicher Bestimmungen zulässig oder Sie haben Ihre
            Einwilligung erteilt.
          </p>
          <p>
            Insoweit müssen wir zur Erbringung unserer Dienste gewisse
            Informationen zu Abwicklungszwecken an andere Nutzer der Plattform
            (Hausverwalter, Eigentümer, Mieter und/oder Dienstleister)
            weitergeben. Diese Informationen werden diesen Personen
            ausschließlich für die Abwicklung unserer Dienste auf der Plattform
            bereitgestellt, in keinem Fall jedoch zu Marketingzwecken.
            Rechtsgrundlage hierfür ist die Erfüllung unserer vertraglichen
            Pflichten (Art. 6 Abs. 1 Buchst. b DSGVO).
          </p>
          <p>
            Im Rahmen der Verarbeitung Ihrer personenbezogenen Daten kann es
            vorkommen, dass wir Ihre personenbezogenen Daten an
            vertrauenswürdige Dienstleister in Drittländern übermitteln.
            Drittländer sind Länder, die außerhalb der Europäischen Union (EU)
            oder des Europäischen Wirtschaftsraums (EWR) liegen.
          </p>
          <p>
            Dabei arbeiten wir nur mit solchen Dienstleistern zusammen, die uns
            geeignete Garantien für die Sicherheit Ihrer personenbezogenen Daten
            geben und garantieren können, dass Ihre personenbezogenen Daten nach
            den strengen europäischen Datenschutzstandards verarbeitet werden.
            Eine Kopie dieser geeigneten Garantien kann bei uns vor Ort
            eingesehen werden.
          </p>
          <p>
            Wenn wir personenbezogene Daten in Drittländern übermitteln, erfolgt
            dies auf der Grundlage eines sogenannten Angemessenheitsbeschlusses
            der Europäischen Kommission, oder, wenn ein solcher Beschluss nicht
            vorliegt, auf der Grundlage von sogenannten
            Standarddatenschutzklauseln, die ebenfalls von der Europäischen
            Kommission erlassen wurden.
          </p>
        </div>
        <h3>5. Speicherdauer</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Ihre personenbezogenen Daten werden von uns nur solange gespeichert,
            wie es für die Erreichung der Zwecke, für die sie erhoben wurden,
            erforderlich ist oder – soweit darüber hinaus gehende gesetzliche
            Aufbewahrungsfristen bestehen – für die Dauer der gesetzlich
            vorgegebenen Aufbewahrung. Im Anschluss werden Ihre
            personenbezogenen Daten gelöscht.
          </p>
        </div>
        <h3>6. Cookies</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Auf der Plattform kommen Cookies zum Einsatz, die für die Navigation
            auf der Plattform und für die Nutzung von dort vorgehaltenen
            unterschiedlichen Optionen oder Diensten erforderlich sind. Diese
            Cookies dienen zudem Authentifizierungs- und Sicherheitszwecken und
            sind erforderlich, um Ihnen das Navigieren und die Nutzung der
            Plattform im eingeloggten Zustand zu ermöglichen. Ferner erlauben
            diese Cookies die Speicherung des Hintergrundbildes der Plattform
            und ermöglichen einen schnelleren Aufbau der Plattform in Ihren
            Browser.
          </p>
          <p>
            Cookies sind Textinformationsdateien, die von unserem Webserver an
            Ihren Computer gesendet und dort abgelegt werden, wenn Sie die
            Plattform besuchen. Die meisten Browser akzeptieren Cookies
            automatisch, können aber über die Einstellungsfunktion des Browsers
            so konfiguriert werden, dass sie diese nicht akzeptieren oder darauf
            hinweisen, wenn ein Cookie gesendet wird. Cookies können abgelehnt
            oder zu einem späteren Zeitpunkt gelöscht werden.
          </p>
          <p>
            Anleitungen zur Löschung von Cookies in den gängigsten Browsern
            finden unter folgenden Links:
          </p>

          <div className="table">
            <div className="table-row">
              <div className="table-cell">Browser</div>
              <div className="table-cell">
                Anleitung zur Löschung von Cookies
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">Microsoft Internet Explorer</div>
              <div className="table-cell">
                <a
                  target="_blank"
                  href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorerdelete- manage-cookies"
                >
                  https://support.microsoft.com/de-de/help/17442/windows-internet-explorerdelete-
                  manage-cookies
                </a>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">Mozilla Firefox</div>
              <div className="table-cell">
                <a
                  target="_blank"
                  href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen"
                >
                  https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen
                </a>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">Google Chrome</div>
              <div className="table-cell">
                <a
                  target="_blank"
                  href="https://support.google.com/chrome/answer/95647?hl=de"
                >
                  https://support.google.com/chrome/answer/95647?hl=de
                </a>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">Apple Safari</div>
              <div className="table-cell">
                <a
                  target="_blank"
                  href="https://support.apple.com/de-de/guide/safari/sfri11471/mac"
                >
                  https://support.apple.com/de-de/guide/safari/sfri11471/mac
                </a>
              </div>
            </div>
            <div className="table-row">
              <div className="table-cell">Opera</div>
              <div className="table-cell">
                <a
                  target="_blank"
                  href="https://help.opera.com/de/latest/security-and-privacy/"
                >
                  https://help.opera.com/de/latest/security-and-privacy/
                </a>
              </div>
            </div>
          </div>
        </div>
        <h3>7. Ihre datenschutzrechtlichen Rechte</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Nach Maßgabe des geltenden Datenschutzrechts stehen Ihnen
            insbesondere die folgenden Rechte zu. Bitte wenden Sie sich hierzu
            an die unter Ziff. 1.1 oben angegebenen Kontaktdaten.
          </p>
          <div>
            <b>a. Recht auf Auskunft:</b>
            <span>
              Sie haben das Recht, jederzeit Auskunft über Ihre von uns
              gespeicherten personenbezogenen Daten zu verlangen.
            </span>
          </div>

          <div>
            <b>b. Recht auf Berichtigung: </b>
            <span>
              Wenn wir Ihre personenbezogenen Daten verarbeiten, bemühen wir uns
              durch angemessene Maßnahmen sicherzustellen, dass Ihre
              personenbezogenen Daten für die Zwecke, für die sie erfasst
              wurden, richtig und aktuell sind. Für den Fall, dass Ihre
              personenbezogenen Daten unrichtig oder unvollständig sind, können
              Sie die Berichtigung dieser Daten verlangen.
            </span>
          </div>
          <div>
            <b>c. Recht auf Löschung und Einschränkung: </b>
            <span>
              Sie haben ggf. das Recht, die Löschung bzw. Einschränkung der
              Verarbeitung ihrer personenbezogenen Daten zu verlangen, wenn z.B.
              für eine solche Verarbeitung gemäß dieser Datenschutzhinweise oder
              geltendem Recht kein legitimer Geschäftszweck mehr besteht und
              gesetzliche Aufbewahrungspflichten einer weiteren Speicherung
              nicht entgegenstehen.
            </span>
          </div>
          <div>
            <b>d. Recht auf Datenübertragbarkeit: </b>
            <span>
              Sie haben ggf. das Recht, die sie betreffenden personenbezogenen
              Daten, die sie uns bereitgestellt haben, in einem strukturierten,
              gängigen und maschinenlesbaren Format zu erhalten oder diese Daten
              einem anderen Verantwortlichen zu übermitteln.
            </span>
            <p className="info-text-border-wrapper">
              e. Recht auf Widerspruch: Sie haben ggf. das Recht, aus besonderen
              Gründen, die sich aus Ihrer besonderen Situation ergeben,
              Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten
              einzulegen.
            </p>
          </div>
          <div>
            <b>f. Recht zum Widerruf Ihrer erteilten Einwilligung: </b>
            <span>
              Sofern Sie in die Erhebung und Verarbeitung Ihrer
              personenbezogenen Daten eingewilligt haben, können Sie Ihre
              Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen,
              jedoch ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung
              bis zum Widerruf erfolgten Verarbeitung dadurch berührt wird.
              Außerdem können Sie der Nutzung Ihrer personenbezogenen Daten für
              die Zwecke der Markt- und Meinungsforschung sowie der Werbung
              widersprechen.
            </span>
          </div>
          <div>
            <b>g. Für mögliche Beschwerden zuständige Aufsichtsbehörde: </b>
            <span>
              Ferner können Sie im Falle einer Beschwerde das Bayerische
              Landesamt für Datenschutzaufsicht, Postfach 606, 91522 Ansbach,
              Telefon +49.981.53-1300, Fax +49.981.5398-1300, E-Mail
              poststelle@lda-bayern.de als die für DOMUS zuständige
              Aufsichtsbehörde kontaktieren. Eine Liste von weiteren in Betracht
              kommenden Aufsichtsbehörden finden Sie unter{" "}
              <a href="https://www.magellan-datenschutz.de" target="_blank">
                https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
              </a>
              .
            </span>
          </div>
        </div>
        <h3>9. Links auf andere Internetseiten</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Die Plattform enthält auch Links zu anderen Internetseiten. Die hier
            beschriebenen Datenschutzhinweise gelten nicht für diese anderen
            Internetseiten. Wir bitten Sie, diese anderen Internetseiten direkt
            zu besuchen, um dort Informationen über den Datenschutz und den
            Umgang mit Ihren personenbezogenen Daten zu erhalten. Wir können für
            von diesen anderen Internetseiten ausgehende Maßnahmen oder für
            deren Inhalt nicht haftbar gemacht werden.
          </p>
        </div>
        <h3>10. Änderungsvorbehalt</h3>
        <div className="data-security-page-container-info-text">
          <p>
            Wir behalten uns das Recht vor, diese Datenschutzhinweise jederzeit
            unter Beachtung der rechtlichen Vorgaben zu ändern. Dies kann z.B.
            bei der Einhaltung neuer Gesetzesbestimmungen oder im Falle neuer
            Dienstleistungen erforderlich sein.
          </p>
        </div>
        <h4>Stand: Oktober 2023</h4>
      </div>
    </div>
  );
}
