import { Field, Form } from "react-final-form";
import { TextAreaForwardRef } from "../common/form/TextArea";
import React, { ReactElement, useCallback, useEffect, useRef } from "react";

import "../../styles/components/bulletinBoard/PostEditComment.scss";
import IconButton from "../common/IconButton";
import Send from "../icons/Send";
import { usePutBulletinBoardPostCommentMutation } from "../../api/bulletinBoardApi";
export default function PostEditComment({
  postId,
  facilityObjectId,
  customerToken,
  commentId,
  text,
}: Props): ReactElement {
  const [postComment, { isLoading }] = usePutBulletinBoardPostCommentMutation();
  const onSubmit = useCallback(async (values: Record<string, string>) => {
    const response = await postComment({
      bulletinBoardPostId: postId,
      facilityObjectId,
      customerToken,
      text: values.comment,
      commentId,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return response?.error ? response.error : undefined;
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ comment: text }}
      render={({ handleSubmit, dirty, form }) => {
        const hasFocus = form.getFieldState("comment")?.active;
        const value = form.getFieldState("comment")?.value;
        const ref = useRef<HTMLTextAreaElement | null>(null);

        useEffect(() => {
          ref.current !== null && ref.current.focus();
        }, [ref]);
        return (
          <form
            onSubmit={handleSubmit}
            className="bulletin-board-post-list-item-edit-comment"
            data-testid="create-comment"
          >
            <Field
              name="comment"
              component={TextAreaForwardRef}
              ref={ref}
              placeholder="kommentieren..."
              rows={hasFocus ? 3 : 1}
              className="bulletin-board-post-list-item-edit-comment-field"
            />
            <IconButton
              icon={Send}
              lightblue
              disabled={!dirty || isLoading || !value}
            />
          </form>
        );
      }}
    />
  );
}

type Props = {
  postId: number;
  commentId: number;
  facilityObjectId: string;
  customerToken: string;
  text: string;
};
