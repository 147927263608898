import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  BulletinBoardRequest,
  BulletinBoard,
  BulletinBoardPostsRequest,
  BulletinBoardPostRequest,
  BulletinBoardPostComment,
  BulletinBoardPostAttachment,
  BulletinBoardPage,
  BulletinBoardPostAttachmentRequest,
  BulletinBoardPostCommentRequest,
  BulletinBoardPostCommentPutRequest,
  BulletinBoardDeleteCommentRequest,
  BulletinBoardUpdatePostRequest,
  BulletinBoardPost,
  BulletinBoardCreatePostRequest,
  BulletinBoardPostCreateAttachmentRequest,
  BulletinBoardPostReportComplainRequest,
  BulletinBoardDeleteAttachmentRequest,
  BulletinBoardPinPostRequest,
  BulletinBoardPinCommentRequest,
  BulletinBoardPostUpdateTenantCommentingAbilityRequest,
} from "../types";
import { BULLETIN_BOARD_REDUCER_PATH } from "../reducerPaths";
import { BASE_URL, prepareHeaders } from "./utils";

export function mergeBulletinPosts(
  currentCache: BulletinBoardPage,
  newItems: BulletinBoardPage,
) {
  if (currentCache?.pageNumber != 0 && newItems.pageNumber !== 0) {
    const items =
      currentCache?.result && newItems?.result
        ? currentCache.result.map((item) => {
            const i = newItems.result.find((newItem) => newItem.id === item.id);
            return i ? i : item;
          })
        : [];

    if (newItems?.result) {
      newItems.result.forEach((item) => {
        const i = items.find((newItem) => newItem.id === item.id);
        if (!i) {
          items.push(item);
        }
      });
    }
    currentCache.result = items;
  } else {
    currentCache.result = newItems.result;
  }

  currentCache.hasMorePages = newItems.hasMorePages;
  currentCache.pageNumber = newItems.pageNumber;
}

export function sortItems(
  a: BulletinBoardPost | BulletinBoardPostComment,
  b: BulletinBoardPost | BulletinBoardPostComment,
) {
  return (
    new Date(b.createDateUTC).getTime() - new Date(a.createDateUTC).getTime()
  );
}
export function sortComments(
  a: BulletinBoardPostComment,
  b: BulletinBoardPostComment,
) {
  return (
    new Date(a.createDateUTC).getTime() - new Date(b.createDateUTC).getTime()
  );
}

export const bulletinBoardApi = createApi({
  reducerPath: BULLETIN_BOARD_REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ["Comment", "Post", "GetAttachment", "BulletinBoard"],
  endpoints: (build) => ({
    getBulletinBoard: build.query<BulletinBoard, BulletinBoardRequest>({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard`,
      }),
      providesTags: (result, _, arg) =>
        result
          ? [{ type: "BulletinBoard" as const, id: arg.facilityObjectId }]
          : ["BulletinBoard"],
    }),
    enableBulletinBoardTenantPosting: build.mutation<
      void,
      BulletinBoardRequest
    >({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/enableTenantPost`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BulletinBoard" as const, id: arg.facilityObjectId },
      ],
    }),
    disableBulletinBoardTenantPosting: build.mutation<
      void,
      BulletinBoardRequest
    >({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/disableTenantPost`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BulletinBoard" as const, id: arg.facilityObjectId },
      ],
    }),
    enableBulletinBoardTenantCommenting: build.mutation<
      void,
      BulletinBoardRequest
    >({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/enableTenantComment`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BulletinBoard" as const, id: arg.facilityObjectId },
      ],
    }),
    disableBulletinBoardTenantCommenting: build.mutation<
      void,
      BulletinBoardRequest
    >({
      query: ({ customerToken, facilityObjectId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/disableTenantComment`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "BulletinBoard" as const, id: arg.facilityObjectId },
      ],
    }),
    getBulletinBoardPosts: build.query<
      BulletinBoardPage,
      BulletinBoardPostsRequest
    >({
      query: ({ customerToken, facilityObjectId, ...params }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts`,
        params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName} ${queryArgs.facilityObjectId}`;
      },
      // Always merge incoming data to the cache entry
      merge: mergeBulletinPosts,
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    deleteBulletinBoardPost: build.mutation<void, BulletinBoardPostRequest>({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        ...params
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}`,
        method: "DELETE",
        params: params,
      }),
      onQueryStarted: async (
        {
          facilityObjectId,
          customerToken,
          bulletinBoardPostId,
          applyToCurrentBulletinBoardOnly,
        },
        { dispatch, queryFulfilled, getState },
      ) => {
        let queryArgs = bulletinBoardApi.util.selectCachedArgsForQuery(
          getState(),
          "getBulletinBoardPosts",
        );

        if (applyToCurrentBulletinBoardOnly) {
          queryArgs = queryArgs.filter(
            (queryArg) =>
              queryArg.customerToken === customerToken &&
              queryArg.facilityObjectId === facilityObjectId,
          );
        }

        const deletedItems = queryArgs.map((queryArg) =>
          dispatch(
            bulletinBoardApi.util.updateQueryData(
              "getBulletinBoardPosts",
              queryArg,
              (draft) => {
                draft.result = draft.result.filter(
                  (item) => item.id !== bulletinBoardPostId,
                );
              },
            ),
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          deletedItems.forEach((deletedItem) => deletedItem.undo());
        }
      },
    }),
    putBulletinBoardPost: build.mutation<
      BulletinBoardPost,
      BulletinBoardUpdatePostRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        ...rest
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}`,
        method: "PUT",
        body: {
          ...rest,
          textMediaType: "string",
          modifyDateUTC: new Date().toUTCString(),
        },
        invalidatesTags: ["GetAttachment"],
      }),
      onCacheEntryAdded: async (
        { facilityObjectId, customerToken, page, bulletinBoardPostId },
        { getCacheEntry, dispatch, cacheDataLoaded },
      ) => {
        await cacheDataLoaded;

        const newItem = dispatch(getCacheEntry);
        dispatch(
          bulletinBoardApi.util.updateQueryData(
            "getBulletinBoardPosts",
            { facilityObjectId, customerToken, page, size: 10 },
            (draft) => {
              const itemIndex = draft.result.findIndex(
                (item) => item.id === bulletinBoardPostId,
              );
              if (itemIndex > -1) {
                draft.result.splice(itemIndex, 1, {
                  ...(newItem.data as BulletinBoardPost),
                  createdByUser: draft.result[itemIndex].createdByUser,
                });
              }
            },
          ),
        );
      },
    }),
    pinBulletinBoardPost: build.mutation<void, BulletinBoardPinPostRequest>({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        pinningType,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/pin`,
        method: "POST",
        body: {
          pinningType,
        },
      }),
      onCacheEntryAdded: async (
        { facilityObjectId, customerToken, bulletinBoardPostId },
        { dispatch },
      ) => {
        dispatch(
          bulletinBoardApi.util.updateQueryData(
            "getBulletinBoardPosts",
            { facilityObjectId, customerToken, page: 1, size: 10 },
            (draft) => {
              const items = draft.result.map((item) => {
                if (item.id === bulletinBoardPostId) {
                  return {
                    ...item,
                    pinned: true,
                  };
                }
                return item;
              });
              draft.result = [
                ...items.filter((item) => item.pinned).sort(sortItems),
                ...items.filter((item) => !item.pinned).sort(sortItems),
              ];
            },
          ),
        );
      },
    }),
    unpinBulletinBoardPost: build.mutation<void, BulletinBoardPinPostRequest>({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        pinningType,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/unpin`,
        method: "POST",
        body: {
          pinningType,
        },
      }),
      onCacheEntryAdded: async (
        { facilityObjectId, customerToken, bulletinBoardPostId },
        { dispatch },
      ) => {
        dispatch(
          bulletinBoardApi.util.updateQueryData(
            "getBulletinBoardPosts",
            { facilityObjectId, customerToken, page: 1, size: 10 },
            (draft) => {
              const items = draft.result.map((item) => {
                if (item.id === bulletinBoardPostId) {
                  return {
                    ...item,
                    pinned: false,
                  };
                }
                return { ...item };
              });
              draft.result = [
                ...items.filter((item) => item.pinned).sort(sortItems),
                ...items.filter((item) => !item.pinned).sort(sortItems),
              ];
            },
          ),
        );
      },
    }),
    enableBulletinBoardPostTenantCommenting: build.mutation<
      void,
      BulletinBoardPostUpdateTenantCommentingAbilityRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        updateTenantCommentingAbilityType,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/enableTenantComment`,
        method: "POST",
        body: {
          updateTenantCommentingAbilityType,
        },
      }),
      onCacheEntryAdded: async (
        { facilityObjectId, customerToken, bulletinBoardPostId },
        { dispatch, cacheDataLoaded },
      ) => {
        await cacheDataLoaded;

        dispatch(
          bulletinBoardApi.util.updateQueryData(
            "getBulletinBoardPosts",
            { facilityObjectId, customerToken, page: 1, size: 10 },
            (draft) => {
              const itemIndex = draft.result.findIndex(
                (item) => item.id === bulletinBoardPostId,
              );

              if (itemIndex > -1) {
                const updatedItem = draft.result[itemIndex];

                draft.result.splice(itemIndex, 1, {
                  ...(updatedItem as BulletinBoardPost),
                  tenantsCanComment: true,
                });
              }
            },
          ),
        );
      },
    }),
    disableBulletinBoardPostTenantCommenting: build.mutation<
      void,
      BulletinBoardPostUpdateTenantCommentingAbilityRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        updateTenantCommentingAbilityType,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/disableTenantComment`,
        method: "POST",
        body: {
          updateTenantCommentingAbilityType,
        },
      }),
      onCacheEntryAdded: async (
        { facilityObjectId, customerToken, bulletinBoardPostId },
        { dispatch, cacheDataLoaded },
      ) => {
        await cacheDataLoaded;

        dispatch(
          bulletinBoardApi.util.updateQueryData(
            "getBulletinBoardPosts",
            { facilityObjectId, customerToken, page: 1, size: 10 },
            (draft) => {
              const itemIndex = draft.result.findIndex(
                (item) => item.id === bulletinBoardPostId,
              );

              if (itemIndex > -1) {
                const updatedItem = draft.result[itemIndex];

                draft.result.splice(itemIndex, 1, {
                  ...(updatedItem as BulletinBoardPost),
                  tenantsCanComment: false,
                });
              }
            },
          ),
        );
      },
    }),
    postBulletinBoardPost: build.mutation<
      BulletinBoardPost,
      BulletinBoardCreatePostRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        page,
        multiplePostingFacilityObjectIds,
        ...rest
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts`,
        method: "POST",
        body: {
          ...rest,
          textMediaType: "string",
          createDateUTC: new Date().toUTCString(),
          canTenantComment: true,
          multiplePostingFacilityObjectIds: multiplePostingFacilityObjectIds,
        },
      }),
      onCacheEntryAdded: async (
        {
          facilityObjectId,
          customerToken,
          page,
        }: BulletinBoardCreatePostRequest,
        { getCacheEntry, dispatch, cacheDataLoaded },
      ) => {
        await cacheDataLoaded;

        const item = dispatch(getCacheEntry);
        dispatch(
          bulletinBoardApi.util.updateQueryData(
            "getBulletinBoardPosts",
            { facilityObjectId, customerToken, page, size: 10 },
            (draft) => {
              if (item.data) {
                draft.result.unshift(item.data);
              }
            },
          ),
        );
      },
    }),

    postBulletinBoardPostAttachment: build.mutation<
      BulletinBoardPostAttachment[],
      BulletinBoardPostCreateAttachmentRequest
    >({
      query: ({
        facilityObjectId,
        customerToken,
        bulletinBoardPostId,
        file,
      }) => {
        const formData = new FormData();
        formData.append("content", file);
        return {
          url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/attachments`,
          method: "POST",
          body: formData,
        };
      },
    }),

    deleteBulletinBoardAttachment: build.mutation<
      void,
      BulletinBoardDeleteAttachmentRequest
    >({
      queryFn: async (
        { customerToken, facilityObjectId, bulletinBoardPostId, deleteFiles },
        api,
        extraOptions,
        baseQuery,
      ) => {
        const deletePromises = deleteFiles.map(({ id }) => {
          return baseQuery({
            url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/attachments/${id}`,
            method: "DELETE",
          });
        });

        await Promise.all(deletePromises);

        return {
          data: undefined,
        };
      },
      invalidatesTags: ["GetAttachment"],
    }),

    getBulletinBoardPost: build.query<
      BulletinBoardPost,
      BulletinBoardPostRequest
    >({
      query: ({ customerToken, facilityObjectId, bulletinBoardPostId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}`,
      }),
      providesTags: (result) =>
        result ? [{ type: "Post" as const, id: result.id }, "Post"] : ["Post"],
    }),
    getBulletinBoardPostComments: build.query<
      BulletinBoardPostComment[],
      BulletinBoardPostRequest
    >({
      query: ({ customerToken, facilityObjectId, bulletinBoardPostId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/comments`,
      }),
      transformResponse: (result: BulletinBoardPostComment[]) => {
        console.log(result);
        return [
          ...result.filter((item) => item.pinned).sort(sortComments),
          ...result.filter((item) => !item.pinned).sort(sortComments),
        ];
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Comment" as const, id })),
              "Comment",
            ]
          : ["Comment"],
    }),
    postBulletinBoardPostComment: build.mutation<
      BulletinBoardPostComment,
      BulletinBoardPostCommentRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        text,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/comments`,
        method: "POST",
        body: {
          text,
          textMediaType: "string",
          createDateUTC: new Date().toUTCString(),
        },
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: "Comment" as const,
                id: result.id,
              },
              "Comment",
            ]
          : ["Comment"],
    }),
    pinBulletinBoardPostComment: build.mutation<
      void,
      BulletinBoardPinCommentRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        commentId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/comments/${commentId}/pin`,
        method: "POST",
      }),
      invalidatesTags: (__, _, arg) => [
        {
          type: "Comment" as const,
          id: arg.commentId,
        },
      ],
    }),
    unpinBulletinBoardPostComment: build.mutation<
      void,
      BulletinBoardPinCommentRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        commentId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/comments/${commentId}/unpin`,
        method: "POST",
      }),
      invalidatesTags: (__, _, arg) => [
        {
          type: "Comment" as const,
          id: arg.commentId,
        },
      ],
    }),
    postBulletinBoardPostReportComplain: build.mutation<
      void,
      BulletinBoardPostReportComplainRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        reason,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/reportComplaint`,
        method: "POST",
        body: {
          reason,
        },
      }),
    }),
    putBulletinBoardPostComment: build.mutation<
      BulletinBoardPostComment,
      BulletinBoardPostCommentPutRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        commentId,
        text,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/comments/${commentId}`,
        method: "PUT",
        body: {
          text,
          textMediaType: "string",
          modifyDateUTC: new Date().toUTCString(),
        },
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: "Comment" as const,
                id: result.id,
              },
              "Comment",
            ]
          : ["Comment"],
    }),
    deleteBulletinBoardPostComment: build.mutation<
      void,
      BulletinBoardDeleteCommentRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        commentId,
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/comments/${commentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: "Comment" as const,
          id: arg.commentId,
        },
        "Comment",
      ],
    }),
    getBulletinBoardPostAttachments: build.query<
      BulletinBoardPostAttachment[],
      BulletinBoardPostRequest
    >({
      query: ({ customerToken, facilityObjectId, bulletinBoardPostId }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/attachments`,
      }),
      providesTags: ["GetAttachment"],
    }),
    getBulletinBoardPostAttachment: build.query<
      Blob,
      BulletinBoardPostAttachmentRequest
    >({
      query: ({
        customerToken,
        facilityObjectId,
        bulletinBoardPostId,
        bulletinBoardPostAttachmentId,
        ...params
      }) => ({
        url: `/customers/${customerToken}/facilityObjects/${facilityObjectId}/bulletinBoard/posts/${bulletinBoardPostId}/attachments/${bulletinBoardPostAttachmentId}`,
        params,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob();
            return Promise.resolve(blob);
          }
          return Promise.resolve(null);
        },
      }),
    }),
  }),
});

export const {
  useGetBulletinBoardPostsQuery,
  useDeleteBulletinBoardPostMutation,
  usePutBulletinBoardPostMutation,
  usePostBulletinBoardPostMutation,
  useGetBulletinBoardQuery,
  useGetBulletinBoardPostAttachmentsQuery,
  useGetBulletinBoardPostAttachmentQuery,
  useLazyGetBulletinBoardPostAttachmentQuery,
  useGetBulletinBoardPostCommentsQuery,
  useDeleteBulletinBoardPostCommentMutation,
  usePostBulletinBoardPostCommentMutation,
  usePostBulletinBoardPostReportComplainMutation,
  usePutBulletinBoardPostCommentMutation,
  usePostBulletinBoardPostAttachmentMutation,
  useDeleteBulletinBoardAttachmentMutation,
  usePinBulletinBoardPostMutation,
  useUnpinBulletinBoardPostMutation,
  usePinBulletinBoardPostCommentMutation,
  useUnpinBulletinBoardPostCommentMutation,
  useEnableBulletinBoardTenantPostingMutation,
  useDisableBulletinBoardTenantPostingMutation,
  useEnableBulletinBoardTenantCommentingMutation,
  useDisableBulletinBoardTenantCommentingMutation,
  useEnableBulletinBoardPostTenantCommentingMutation,
  useDisableBulletinBoardPostTenantCommentingMutation,
} = bulletinBoardApi;
