import React, { Fragment, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import ApiError from "../icons/ApiError";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import "../../styles/components/common/FetchApiError.scss";
import LoadingSpinner from "../icons/LoadingSpinner";
import classNames from "classnames";

export default function FetchApiError({
  error,
  notFoundElement,
}: Props): JSX.Element {
  const { customerToken = "" } = useParams();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const status = error?.originalStatus || error?.status;
  const isUnauthorized = status === 401 || status === 0 || status === 403;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isNotFoundError = error?.status === 404;

  useEffect(() => {
    if (isUnauthorized) {
      window.location.href = `/anmelden${
        customerToken.length ? `/${customerToken}` : ""
      }?redirectTo=${encodeURIComponent(location.pathname + location.search)}`;
    }
  }, [isUnauthorized]);

  return isNotFoundError && notFoundElement ? (
    <div className="custom-api-error">{notFoundElement}</div>
  ) : (
    <section
      className={classNames("api-error", { isUnauthorized })}
      data-testid="api-error"
    >
      {!isUnauthorized && (
        <Fragment>
          <ApiError className="api-error-image" />
          <h2 className="api-error-title">Da ist etwas schief gelaufen.</h2>
          {error && (
            <details className="api-error-details">
              <summary>Reason</summary>
              <code>{JSON.stringify(error)}</code>
            </details>
          )}
        </Fragment>
      )}
      {isUnauthorized && (
        <div className="page-loading">
          <LoadingSpinner />
        </div>
      )}
    </section>
  );
}

type Props = {
  error?: SerializedError | FetchBaseQueryError;
  notFoundElement?: JSX.Element;
};
