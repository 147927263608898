import { Helmet } from "react-helmet";
import PageHeader from "../../common/PageHeader";
import React, { SyntheticEvent, useCallback, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import "../../../styles/components/pages/messages/IndexMessagesPage.scss";
import SearchField from "../../common/SearchField";

export default function IndexMessagePage(): JSX.Element {
  const { messageId = "" } = useParams();
  const [query, setQuery] = useState<string>("");
  const title = "Nachrichten";
  const backTo = !messageId
    ? undefined
    : { to: "../nachrichten", label: "Alle Nachrichten" };

  const onSearch = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim();
    setQuery(value);
  }, []);

  return (
    <div className="messages">
      <Helmet>
        <title>{title} - Homecase</title>
      </Helmet>
      <PageHeader
        title={title}
        backTo={backTo}
        searchComponent={SearchField}
        onSearch={onSearch}
      />
      <div className="messages-container">
        <Outlet context={[query]} />
      </div>
    </div>
  );
}
