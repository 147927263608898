import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import PageHeader from "../../common/PageHeader";

import SearchField from "../../common/SearchField";

import { DocumentsQueryType, GroupingType, UserRoles } from "../../../enums";
import TabBar from "../../common/TabBar";
import VirtualTab from "../../common/VirtualTab";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import useFetchPostLoginBaseData from "../../../hooks/useFetchPostLoginBaseData";
import { useLazyGetOnlineDocumentsQuery } from "../../../api/documentsApi";
import FetchApiError from "../../common/FetchApiError";
import DocumentList from "../../documents/DocumentList";
import LightboxProvider from "../../common/lightbox/LightboxProvider";
import useSearchDocument from "../../../hooks/documents/useSearchDocument";
import useTenanciesAndOwnershipsOptions from "../../../hooks/documents/useTenanciesAndOwnershipsOptions";
import SelectField from "../../common/SelectField";

import TableSortHeader from "../../common/TableSortHeader";
import useTableSort from "../../../hooks/common/useTableSort";

import useSortDocuments from "../../../hooks/documents/useSortDocuments";
import DocumentsPageHeaderActionsGroup from "../../documents/DocumentsPageHeaderActionsGroup";
import useLocalStorage from "../../../hooks/useLocalStorage";

import "../../../styles/components/pages/DocumentsPage.scss";

export default function DocumentsPage(): ReactElement {
  const roles = useCustomerRoles();
  const [isContentVisible, setIsContentVisible] = useLocalStorage(
    "documents-content-visible-toggle-button",
    false,
  );
  const [isGroupedBy, setGroupedBy] = useLocalStorage(
    "documents-grouped-by",
    GroupingType.DocumentCategory,
  );

  const [isSortingNotHidden, setSortingNotHidden] = useState(false);
  const [activeTab, setActiveTab] = useState<string | number>(
    DocumentsQueryType.FacilityObjectDocuments,
  );
  const [
    selectedTenanciesAndOwnershipsOptions,
    setSelectedTenanciesAndOwnershipsOptions,
  ] = useState<number | null>(null);
  const { selectedAssignedObject } = useFetchPostLoginBaseData();
  const [getOnlineDocuments, { data = [], isFetching, isError, error }] =
    useLazyGetOnlineDocumentsQuery();
  const { searchedDocuments, handleSearch, term } = useSearchDocument(data);
  const showTenancyOrOwnershipDocuments =
    roles.includes(UserRoles.Employee) || roles.includes(UserRoles.ObjectOwner);
  const tenanciesAndOwnershipsOptions = useTenanciesAndOwnershipsOptions(
    !showTenancyOrOwnershipDocuments,
  );
  const { sort, handleSort } = useTableSort(
    {
      sortBy: "createDateUTC",
      sortDirection: "descending",
    },
    "documents-list",
  );
  const sortedDocuments = useSortDocuments(searchedDocuments, sort);
  const toggleContent = useCallback(() => {
    setIsContentVisible(!isContentVisible);
  }, [isContentVisible]);

  const onSelect = useCallback((e: SyntheticEvent<HTMLSelectElement>) => {
    setSelectedTenanciesAndOwnershipsOptions(
      parseInt(e.currentTarget.value, 10),
    );
  }, []);

  const handleSelect = useCallback((groupedBy: GroupingType) => {
    setGroupedBy(groupedBy);
  }, []);

  useEffect(() => {
    if (term) {
      setGroupedBy(GroupingType.None);
    }
  }, [term]);

  const sortOptions =
    isGroupedBy === GroupingType.None
      ? [
          {
            sortBy: "originalFileName",
            label: "Dateiname",
          },
          {
            sortBy: "category",
            label: "Kategorie",
          },

          {
            sortBy: "contentSize",
            label: "Größe",
          },
          {
            sortBy: "createDateUTC",
            label: "Datum",
          },
        ]
      : [
          {
            sortBy: "originalFileName",
            label: "Dateiname",
          },

          {
            sortBy: "contentSize",
            label: "Größe",
          },
          {
            sortBy: "createDateUTC",
            label: "Datum",
          },
        ];
  return (
    <div className="documents-page-container">
      <Helmet>
        <title> Dokumente - Homecase</title>
      </Helmet>
      <PageHeader
        title="Dokumente"
        searchComponent={SearchField}
        onSearch={handleSearch}
        actionComponent={DocumentsPageHeaderActionsGroup}
        actionComponentProps={{
          groupingType: isGroupedBy,
          onSelect: handleSelect,
        }}
      />
      <section className="documents-page-container-section">
        <TabBar>
          <VirtualTab
            tabId={DocumentsQueryType.FacilityObjectDocuments}
            label="Objektdokumente"
            onClick={setActiveTab}
            active={activeTab === DocumentsQueryType.FacilityObjectDocuments}
          />
          {!roles.includes(UserRoles.Employee) && (
            <VirtualTab
              tabId={DocumentsQueryType.CurrentUserDocuments}
              label="Persönliche Dokumente"
              onClick={setActiveTab}
              active={activeTab === DocumentsQueryType.CurrentUserDocuments}
            />
          )}
          {showTenancyOrOwnershipDocuments && (
            <VirtualTab
              tabId={DocumentsQueryType.SelectedTenancyOrOwnershipDocuments}
              label={
                selectedAssignedObject?.isAocFacilityObject
                  ? "Eigentümerdokumente"
                  : "Mieterdokumente"
              }
              onClick={setActiveTab}
              active={
                activeTab ===
                DocumentsQueryType.SelectedTenancyOrOwnershipDocuments
              }
            />
          )}
        </TabBar>
        {activeTab ===
          DocumentsQueryType.SelectedTenancyOrOwnershipDocuments && (
          <div className="documents-page-container-selector">
            <SelectField
              options={tenanciesAndOwnershipsOptions}
              label=""
              onChange={onSelect}
              unselectable="on"
              defaultValue={
                selectedTenanciesAndOwnershipsOptions !== null
                  ? selectedTenanciesAndOwnershipsOptions
                  : ""
              }
            />
          </div>
        )}
        <div className="sort-header-wrapper">
          {isGroupedBy !== GroupingType.None && (
            <div className="toggle-wrapper">
              <span
                role="toggle-button"
                className={`toggle-sign ${isContentVisible ? "minus" : ""}`}
                onClick={toggleContent}
                title={
                  isContentVisible
                    ? "Alle Kategorien einklappen"
                    : "Alle Kategorien ausklappen"
                }
              ></span>
              <span className="toggle-info-text">
                {isContentVisible
                  ? "Alle Kategorien einklappen"
                  : "Alle Kategorien ausklappen"}
              </span>
            </div>
          )}

          <TableSortHeader
            handleSort={handleSort}
            sortOptions={sortOptions}
            sort={sort}
            className={
              !isSortingNotHidden && isGroupedBy !== GroupingType.None
                ? "hidden"
                : ""
            }
          />
        </div>
        <DocumentList
          term={term}
          documents={sortedDocuments}
          getOnlineDocuments={getOnlineDocuments}
          isFetching={isFetching}
          queryType={activeTab as DocumentsQueryType}
          tenancyOrOwnershipId={
            activeTab === DocumentsQueryType.SelectedTenancyOrOwnershipDocuments
              ? selectedTenanciesAndOwnershipsOptions
              : undefined
          }
          isContentVisible={isContentVisible}
          isGroupedBy={isGroupedBy}
          setSortingNotHidden={setSortingNotHidden}
        />
        <LightboxProvider options={{ showMeta: true, closeButton: true }}>
          <Outlet context={data} />
        </LightboxProvider>
        {isError && <FetchApiError error={error} />}
      </section>
    </div>
  );
}
