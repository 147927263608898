import React, { ReactElement, useEffect, useMemo } from "react";
import { Participant } from "../../types";
import {
  MeetingState,
  ParticipantAttendanceType,
  SynchronisationMethod,
} from "../../enums";
import ProfilePicture from "../common/ProfilePicture";
import useLocallyAttendMeeting from "../../hooks/meetings/useLocallyAttendMeeting";
import LoadingSpinner from "../icons/LoadingSpinner";

import ErrorDialog from "../common/dialog/ErrorDialog";
import ParticipantDelegation from "./participants/ParticipantDelegation";
import { useSynchronisation } from "../../hooks/useSynchronisation";

import {
  getReverseParticipantDisplayName,
  shouldRenderParticipantFurtherRepresentatives,
} from "../../lib/meeting";
import ParticipantFurtherRepresentatives from "./participants/ParticipantFurtherRepresentatives";

import ParticipantAttendance from "./participants/ParticipantAttendance";

import "../../styles/components/meetings/ParticipantsListItem.scss";
import useMeetingState from "../../hooks/meetings/useMeetingState";

export default function ParticipantsListItem({
  participants,
  participant,
  selectableVotingEligibilityParticipants,
  readonly,
}: Props): ReactElement {
  const {
    handleAttendanceChange,
    participantAttendance,
    retryAttendanceChange,
    isLoading,
    error,
    isSuccess,
  } = useLocallyAttendMeeting(participant);

  const { sync } = useSynchronisation();
  const meetingState = useMeetingState();

  useEffect(() => {
    if (isSuccess) {
      sync(SynchronisationMethod.ParticipantsChanged);
    }
  }, [isSuccess]);

  const delegatedPerson = useMemo(() => {
    return participants?.find(
      (participantItem) => participant.delegate?.id === participantItem.id,
    );
  }, [participants]);

  const isDelegate = useMemo(() => {
    return (
      participant.delegationType === 2 ||
      ([ParticipantAttendanceType.Abscent].includes(participantAttendance) &&
        participant.delegate &&
        delegatedPerson &&
        [
          ParticipantAttendanceType.Online,
          ParticipantAttendanceType.Locally,
        ].includes(delegatedPerson.attendance))
    );
  }, [participant, delegatedPerson]);

  const mouseoverTitle =
    participant.facilityAssignments &&
    participant.facilityAssignments.length > 3
      ? (participant.facilityAssignments || [])
          .map(({ facilityUnitDescription }) => facilityUnitDescription)
          .filter(Boolean)
          .join("\n")
      : "";

  return (
    <div role="row" className="etv-participants-list-item">
      <div
        className="etv-participants-list-item-name"
        data-testid="participant-name"
        role="cell"
      >
        <ProfilePicture
          alt={participant.lastName || "profile picture"}
          userSid={participant.userSid}
          onlineState={participant.attendance}
          width={32}
          height={32}
          delegatedPerson={delegatedPerson}
          isDelegate={isDelegate}
          rounded
        />
        <div>
          <p>{getReverseParticipantDisplayName(participant, ",")}</p>
          {shouldRenderParticipantFurtherRepresentatives(participant) && (
            <ParticipantFurtherRepresentatives
              participant={participant}
              readOnly={readonly}
            ></ParticipantFurtherRepresentatives>
          )}
        </div>
      </div>
      <div
        className="etv-participants-list-item-unit"
        data-testid="participant-unit"
        role="cell"
        title={mouseoverTitle}
      >
        {participant.facilityAssignments &&
          participant.facilityAssignments.slice(0, 3).map(
            ({ facilityUnitDescription }, index) =>
              facilityUnitDescription && (
                <span key={facilityUnitDescription}>
                  {facilityUnitDescription}
                  {index === 2 &&
                    participant.facilityAssignments &&
                    participant.facilityAssignments.length > 3 && (
                      <span>...</span>
                    )}
                </span>
              ),
          )}
      </div>
      <div
        className="etv-participants-list-item-attendance"
        data-testid="participant-attendance"
        role="cell"
      >
        {isLoading && (
          <LoadingSpinner
            width={16}
            height={16}
            className="etv-participants-list-item-attendance-loading"
          />
        )}
        <ParticipantAttendance participant={participant} />
      </div>
      <div
        className="etv-participants-list-item-actions"
        data-testid="participant-actions"
        role="cell"
      >
        <input
          type="checkbox"
          value={participant.id}
          onChange={handleAttendanceChange}
          checked={participantAttendance !== ParticipantAttendanceType.Abscent}
          disabled={
            readonly || meetingState === MeetingState.OwnerAndEmployeePreview
          }
          data-testid="participant-attendance-checkbox"
        />
      </div>
      <div
        className="etv-participants-list-item-delegate"
        data-testid="participant-delegate"
        role="cell"
      >
        {participantAttendance === ParticipantAttendanceType.Abscent && (
          <ParticipantDelegation
            participant={participant}
            selectableVotingEligibilityParticipants={
              selectableVotingEligibilityParticipants
            }
            readOnly={readonly}
          />
        )}
      </div>

      <ErrorDialog
        description="Vor Ort Teilnahme konnte auf Grund eines Fehlers nicht gespeichert werden."
        title="Es ist ein Fehler aufgetreten!"
        onRetry={retryAttendanceChange}
        error={error}
      />
    </div>
  );
}

type Props = {
  participant: Participant;
  selectableVotingEligibilityParticipants: Participant[];
  participants?: Participant[];
  readonly: boolean;
};
