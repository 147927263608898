import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Participant } from "../../../types";
import classNames from "classnames";
import ProfilePicture from "../../common/ProfilePicture";

import "../../../styles/components/meetings/participants/VotingEligibilityDropdown.scss";
import { getParticipantDisplayName } from "../../../lib/meeting";

export default function VotingEligibilityDropdown({
  selectableVotingEligibilityParticipants,
  participant,
  delegateParticipant,
  closestSelector,
  onSelect,
  readOnly,
}: Props): JSX.Element {
  const [showDatalist, setShowDatalist] = useState({
    visible: false,
    top: false,
  });
  const [value, setValue] = useState<{
    input: string;
    participant: Participant | undefined;
  }>({
    input: delegateParticipant
      ? getParticipantDisplayName(delegateParticipant)
      : "",
    participant: delegateParticipant,
  });
  const ref = useRef<HTMLDivElement>(null);
  const dataList = selectableVotingEligibilityParticipants.filter(
    ({ id }) => id !== participant.id,
  );

  const [options, setOptions] = useState(dataList);

  useEffect(() => {
    setOptions(dataList);
  }, [selectableVotingEligibilityParticipants]);

  const handleShowDatalist = useCallback(() => {
    const { y } = ref.current?.getBoundingClientRect() || { y: 0 };
    const parentClientHeight =
      ref.current?.closest(closestSelector)?.clientHeight || 0;
    setShowDatalist({
      visible: !showDatalist.visible,
      top: y > parentClientHeight,
    });
  }, [showDatalist, setShowDatalist, ref]);

  const handleSearchDataList = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const searchString = e.currentTarget.value;
      const filteredOptions = selectableVotingEligibilityParticipants.filter(
        (p) =>
          getParticipantDisplayName(p)
            .toLowerCase()
            .includes(searchString.toLowerCase()),
      );
      setValue({ input: searchString, participant: undefined });
      setOptions(filteredOptions);
    },
    [options, setOptions, selectableVotingEligibilityParticipants],
  );

  const handleSelectOption = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      const participantId = e.currentTarget.getAttribute("data-id");
      const participant = selectableVotingEligibilityParticipants.find(
        (p) => p.id === participantId,
      );
      if (participant) {
        setValue({
          input: getParticipantDisplayName(participant),
          participant,
        });
        setOptions(dataList);
        onSelect(participant);
      }
    },
    [setValue, selectableVotingEligibilityParticipants],
  );

  const handleDeselectOption = useCallback(() => {
    setValue({
      input: "",
      participant: undefined,
    });
    setOptions(dataList);
    onSelect(undefined);
  }, []);

  const datalistClass = classNames({
    "voting-eligibility-dropdown-list": true,
    "voting-eligibility-dropdown-list-open": true,
    "voting-eligibility-dropdown-list-top": showDatalist.top,
  });

  useEffect(() => {
    if (
      delegateParticipant &&
      (delegateParticipant.id !== value.participant?.id ||
        delegateParticipant.firstName !== value.participant?.firstName ||
        delegateParticipant.lastName !== value.participant?.lastName)
    ) {
      setValue({
        input: getParticipantDisplayName(delegateParticipant),
        participant: delegateParticipant,
      });
    }
    if (!delegateParticipant && value.participant) {
      setValue({
        input: "",
        participant: undefined,
      });
    }
  }, [delegateParticipant]);

  const votingEligibilityDropdownClassName = classNames(
    "voting-eligibility-dropdown",
    {
      readonly: readOnly,
    },
  );

  return (
    <div
      className={votingEligibilityDropdownClassName}
      ref={ref}
      data-testid="voting-eligibility-dropdown"
    >
      <ProfilePicture
        alt={"profile picture"}
        width={32}
        height={32}
        rounded
        userSid={delegateParticipant?.userSid}
        onlineState={delegateParticipant?.attendance}
      />
      <input
        className="voting-eligibility-dropdown-input"
        name="delegate"
        list="participantDelegate"
        value={value.input}
        data-testid="voting-eligibility-dropdown-input"
        placeholder="Teilnehmer auswählen"
        autoComplete="off"
        onFocus={handleShowDatalist}
        onBlur={handleShowDatalist}
        onInput={handleSearchDataList}
      />
      {value.participant && (
        <div
          className="voting-eligibility-dropdown-deselect"
          onClick={handleDeselectOption}
          data-testid="voting-eligibility-dropdown-deselect"
        >
          X
        </div>
      )}
      {showDatalist.visible && (
        <section role="datalist" className={datalistClass}>
          {options.map((p) => (
            <div
              className="voting-eligibility-dropdown-option"
              key={p.id}
              data-id={p.id}
              onMouseDown={handleSelectOption}
            >
              <ProfilePicture
                alt={p.lastName || "profile picture"}
                userSid={p.userSid}
                width={32}
                height={32}
                rounded
              />
              <span>{getParticipantDisplayName(p)}</span>
            </div>
          ))}
        </section>
      )}
    </div>
  );
}

type Props = {
  selectableVotingEligibilityParticipants: Participant[];
  participant: Participant;
  delegateParticipant?: Participant;
  closestSelector: string;
  onSelect: (participant: Participant | undefined) => void;
  readOnly: boolean;
};
