import { Field, Form } from "react-final-form";
import classNames from "classnames";
import { TextAreaForwardRef } from "../common/form/TextArea";
import React, { useCallback, useEffect, useRef, useState } from "react";

import IconButton from "../common/IconButton";

import "../../styles/components/bulletinBoard/PostEdit.scss";

import Checkmark from "../icons/Checkmark";
import FilePreview from "../common/form/FilePreview";
import FileInput from "../common/form/FileInput";
import {
  validateFileFirstBytes,
  validateFiles,
  validateFileType,
} from "../../lib/formValidate";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useUpdatePost from "../../hooks/bulletinBoard/useUpdatePost";
import useInitialAttachments from "../../hooks/bulletinBoard/useInitialAttachments";

export default function PostEdit({
  postId,
  text,
  page,
  onEditSuccessful,
}: Props): JSX.Element {
  const ref = useRef(null);
  const [hasFocus, setHasFocus] = useState(false);
  const { save, isLoading } = useUpdatePost(postId, page);
  const { initialAttachmentValues, initialItems } =
    useInitialAttachments(postId);
  const onSubmit = useCallback(
    async (values: { post: string; attachments?: File[] }) => {
      const error = await save(values, initialAttachmentValues, initialItems);

      !error && onEditSuccessful && onEditSuccessful();
      return error;
    },
    [initialAttachmentValues, initialItems],
  );

  const handleFocus = useCallback(() => setHasFocus(true), []);
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ post: text, attachments: initialAttachmentValues }}
      render={({ handleSubmit, dirty, form }) => {
        const value = form.getFieldState("post")?.value;

        const handleBlur = useCallback(() => {
          if (!dirty) {
            setHasFocus(false);
            onEditSuccessful && onEditSuccessful();
          }
        }, [dirty]);

        useOnClickOutside(ref, handleBlur);

        useEffect(() => {
          ref.current !== null && setHasFocus(true);
        }, [ref]);
        return (
          <form
            onSubmit={handleSubmit}
            className="bulletin-board-post-list-item-edit-post"
            data-testid="edit-post"
            ref={ref}
            onFocus={handleFocus}
          >
            <div
              className={classNames(
                "bulletin-board-post-list-item-edit-post-fields",
                {
                  hasFocus,
                },
              )}
              data-testid="edit-post-fields"
            >
              <Field
                name="post"
                component={TextAreaForwardRef}
                placeholder="Beitrag schreiben..."
                rows={hasFocus ? 3 : 1}
                className="bulletin-board-post-list-item-edit-post-field"
                maxLength={2000}
              />
              <div className="bulletin-board-post-list-item-edit-post-fields-inner">
                <div className="bulletin-board-post-list-item-edit-post-upload">
                  <Field
                    component={FilePreview}
                    name="attachments"
                    subscription={{ error: true, value: true, touched: true }}
                    postId={postId}
                  />
                  <Field
                    component={FileInput}
                    name="attachments"
                    width={32}
                    height={32}
                    validate={validateFiles(
                      validateFileType(),
                      validateFileFirstBytes(),
                    )}
                  />
                  <IconButton
                    icon={Checkmark}
                    lightblue
                    disabled={!dirty || isLoading || !value}
                  />
                </div>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
}

type Props = {
  postId: number;
  text?: string;
  page: number;
  onEditSuccessful?: () => void;
};
