import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import { useGetAgendaItemsQuery } from "../../../api/agendaItemApi";
import AgendaItemDetails from "../../meetings/agendaItem/AgendaItemDetails";
import Details from "../../common/Details";
import FetchApiError from "../../common/FetchApiError";
import usePageVisibilityEffect from "../../../hooks/usePageVisibilityEffect";
import useMeetingState from "../../../hooks/meetings/useMeetingState";
import { MeetingState, SynchronisationMethod, UserRoles } from "../../../enums";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import { useSynchronisation } from "../../../hooks/useSynchronisation";

export default function AgendaItemPage(): JSX.Element {
  const {
    customerToken = "",
    meetingId = "",
    agendaItemId = "",
    facilityObjectId = "",
  } = useParams();
  const meetingState = useMeetingState(meetingId);
  const customerRoles = useCustomerRoles();
  const navigate = useNavigate();
  const navigationIsVisible =
    customerRoles.includes(UserRoles.Employee) ||
    [MeetingState.Closed, MeetingState.Running].includes(meetingState);
  const {
    isLoading,
    data = [],
    isError,
    error,
    refetch,
  } = useGetAgendaItemsQuery(
    {
      customerToken,
      meetingId,
      facilityObjectId,
    },
    {
      skip: !navigationIsVisible,
    },
  );
  const selectedItem = data.find(({ id }) => id === agendaItemId);

  usePageVisibilityEffect((visibilityState) => {
    if (
      meetingState === MeetingState.Running &&
      visibilityState === "visible"
    ) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (!navigationIsVisible) {
      navigate("../");
    }
  }, [navigationIsVisible, navigate]);
  const { connection } = useSynchronisation();

  useEffect(() => {
    if (connection) {
      connection.on(SynchronisationMethod.AgendaItemChanged, refetch);
    }
    return () => {
      if (connection) {
        connection.off(SynchronisationMethod.AgendaItemChanged);
      }
    };
  }, [connection]);

  return (
    <div className="etv-agenda-item">
      {isLoading && (
        <Details summary="Beschreibung" open controlId="skeleton">
          <Skeleton count={2} />
        </Details>
      )}
      {selectedItem && <AgendaItemDetails item={selectedItem} />}
      {isError && <FetchApiError error={error} />}
    </div>
  );
}
