import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import { Participant } from "../../../types";
import {
  getParticipantDisplayName,
  getReverseParticipantRepresentativeDisplayName,
} from "../../../lib/meeting";
import { useSetParticipantRepresentativeMutation } from "../../../api/participantsApi";

import "../../../styles/components/meetings/ParticipantFurtherRepresentatives.scss";
import LoadingSpinner from "../../icons/LoadingSpinner";
import ErrorDialog from "../../common/dialog/ErrorDialog";
import classNames from "classnames";

export default function ParticipantFurtherRepresentatives({
  participant,
  readOnly,
}: Props): JSX.Element {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();

  const [setParticipantRepresentative, { isLoading, isError, error }] =
    useSetParticipantRepresentativeMutation();

  const onClickHandler = useCallback(
    async (participantRepresentativeId: string) => {
      if (readOnly) return;

      const response = await setParticipantRepresentative({
        customerToken,
        facilityObjectId,
        meetingId,
        participantId: participant.id,
        participantRepresentativeId,
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return response?.error ? response.error : undefined;
    },
    [],
  );

  const furtherRepresentatives = participant.furtherRepresentatives ?? [];

  const furtherRepresentativeClassNames = classNames(
    "etv-participant-representatives-item",
    {
      readonly: readOnly,
    },
  );

  return (
    <div className="etv-participant-representatives">
      {furtherRepresentatives.map((representative) => {
        return (
          <span
            className={furtherRepresentativeClassNames}
            onClick={() => onClickHandler(representative.id)}
            key={`key-${representative.id}`}
            data-testid="participant-representative-name"
          >
            {getReverseParticipantRepresentativeDisplayName(representative)}
          </span>
        );
      })}

      {isLoading && <LoadingSpinner height={16} width={16} />}

      {!isLoading && isError && (
        <ErrorDialog
          description={`Der Repräsentant des Teilnehmers ${getParticipantDisplayName(
            participant,
          )} kann nicht ausgewählt werden.`}
          title="Der Repräsentant des Teilnehmers kann nicht ausgewählt werden."
          error={error}
        />
      )}
    </div>
  );
}

type Props = {
  participant: Participant;
  readOnly: boolean;
};
