import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import SearchIcon from "../icons/SearchIcon";
import classNames from "classnames";

import "../../styles/components/common/SearchField.scss";

export default function SearchField({
  onChange,
  placeholder,
  collapsableOnMobile = false,
}: Props): ReactElement {
  const [collapsed, setCollapsed] = useState(collapsableOnMobile);
  const toggleMobileCollapsed = useCallback(() => {
    if (collapsableOnMobile) {
      setCollapsed(!collapsed);
    }
  }, [collapsed, collapsableOnMobile]);
  return (
    <div
      className={classNames("search", {
        "search-mobile-collapsed": collapsed,
        "search-mobile-collapsable": collapsableOnMobile,
      })}
      data-testid="search-field"
    >
      <label>
        <SearchIcon width={32} height={32} />
        <input
          type="search"
          className="search-input"
          onChange={onChange}
          placeholder={placeholder}
          onBlur={toggleMobileCollapsed}
          onFocus={toggleMobileCollapsed}
        />
      </label>
    </div>
  );
}

type Props = {
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  placeholder?: string;
  collapsableOnMobile?: boolean;
};
