import { Field, Form } from "react-final-form";
import TextArea from "../common/form/TextArea";
import useFetchUserProfile from "../../hooks/useFetchUserProfile";
import ProfilePicture from "../common/ProfilePicture";
import React, { ReactElement, useCallback, useEffect } from "react";

import "../../styles/components/bulletinBoard/PostCreateComment.scss";
import IconButton from "../common/IconButton";
import Send from "../icons/Send";
import { usePostBulletinBoardPostCommentMutation } from "../../api/bulletinBoardApi";
import ErrorDialog from "../common/dialog/ErrorDialog";
export default function PostCreateComment({
  postId,
  facilityObjectId,
  customerToken,
}: Props): ReactElement {
  const user = useFetchUserProfile();
  const [postComment, { isLoading, isError, error }] =
    usePostBulletinBoardPostCommentMutation();
  const onSubmit = useCallback(async (values: Record<string, string>) => {
    const response = await postComment({
      bulletinBoardPostId: postId,
      facilityObjectId,
      customerToken,
      text: values.comment,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return response?.error ? response.error : undefined;
  }, []);
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, dirty, form, submitSucceeded }) => {
        const hasFocus = form.getFieldState("comment")?.active || dirty;

        useEffect(() => {
          form.reset();
        }, [submitSucceeded]);

        return (
          <form
            onSubmit={handleSubmit}
            className="bulletin-board-post-list-item-create-comment"
            data-testid="create-comment"
          >
            <div className="bulletin-board-post-list-item-create-comment-user">
              <ProfilePicture
                alt={user.firstName}
                userSid={user.sid}
                width={32}
                height={32}
                rounded
              />
            </div>
            <Field
              name="comment"
              component={TextArea}
              placeholder="kommentieren..."
              rows={hasFocus ? 3 : 1}
              className="bulletin-board-post-list-item-create-comment-field"
              maxLength={2000}
            />
            <IconButton icon={Send} lightblue disabled={!dirty || isLoading} />
            {isError && (
              <ErrorDialog
                description="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
                title="Es ist ein Fehler aufgetreten!"
                onRetry={handleSubmit}
                error={error}
              />
            )}
          </form>
        );
      }}
    />
  );
}

type Props = {
  postId: number;
  facilityObjectId: string;
  customerToken: string;
};
