import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  useSetVotingEligibilityMutation,
  useRetainVotingEligibilityMutation,
  useSetVotingEligibilityForParticipantsMutation,
  useRetainVotingEligibilityForParticipantMutation,
} from "../../api/participantsApi";
import { Participant } from "../../types";
import { ParticipantDelegationType } from "../../enums";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export default function useSetVotingEligibility(
  participant: Participant,
): SetVotingEligibility {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();

  const [
    setVotingEligibility,
    {
      isLoading: setIsLoading,
      error: setError,
      originalArgs: setOriginalArgs,
      isSuccess: setIsSuccess,
    },
  ] = useSetVotingEligibilityMutation();

  const [setVotingEligibilityForParticipants] =
    useSetVotingEligibilityForParticipantsMutation();

  const [
    retainVotingEligibility,
    {
      isLoading: retainIsLoading,
      error: retainError,
      originalArgs: retainOriginalArgs,
      isSuccess: retainIsSuccess,
    },
  ] = useRetainVotingEligibilityMutation();

  const [retainVotingEligibilityForParticipant] =
    useRetainVotingEligibilityForParticipantMutation();

  const handleDelegationSelect = useCallback(
    (selectedParticipant: Participant | undefined) => {
      if (selectedParticipant !== undefined) {
        setVotingEligibility({
          customerToken,
          facilityObjectId,
          meetingId,
          participantId: participant.id,
          delegationType: !!selectedParticipant.id
            ? ParticipantDelegationType.VotingEligibilityDelegatedToParticipant
            : ParticipantDelegationType.VotingEligibilityDelegatedToEmployee,
          delegateId: !!selectedParticipant.id
            ? selectedParticipant.id
            : undefined,
        });
      }
      if (selectedParticipant === undefined) {
        retainVotingEligibility({
          customerToken,
          facilityObjectId,
          meetingId,
          participantId: participant.id,
        });
      }
    },
    [meetingId, facilityObjectId],
  );

  const handleParticipantDelegationSelect = useCallback(
    async (selectedParticipant: Participant | undefined) => {
      if (selectedParticipant !== undefined) {
        return setVotingEligibilityForParticipants({
          customerToken,
          facilityObjectId,
          meetingId,
          participantId: participant.id,
          delegationType: !!selectedParticipant.id
            ? ParticipantDelegationType.VotingEligibilityDelegatedToParticipant
            : ParticipantDelegationType.VotingEligibilityDelegatedToEmployee,
          delegateId: !!selectedParticipant.id
            ? selectedParticipant.id
            : undefined,
        });
      }

      return retainVotingEligibilityForParticipant({
        customerToken,
        facilityObjectId,
        meetingId,
      });
    },
    [meetingId, facilityObjectId],
  );

  const retry = useCallback(() => {
    if (setOriginalArgs) {
      setVotingEligibility(setOriginalArgs);
    }
    if (retainOriginalArgs) {
      retainVotingEligibility(retainOriginalArgs);
    }
  }, [
    setVotingEligibility,
    retainVotingEligibility,
    setOriginalArgs,
    retainOriginalArgs,
  ]);

  return {
    handleDelegationSelect,
    handleParticipantDelegationSelect,
    isLoading: setIsLoading || retainIsLoading,
    setIsSuccess,
    retainIsSuccess,
    error: setError || retainError,
    retry,
  };
}

type SetVotingEligibility = {
  handleDelegationSelect: (
    selectedParticipant: Participant | undefined,
  ) => void;
  handleParticipantDelegationSelect: (
    selectedParticipant: Participant | undefined,
  ) => Promise<
    { data: void } | { error: FetchBaseQueryError | SerializedError }
  >;
  isLoading: boolean;
  setIsSuccess: boolean;
  retainIsSuccess: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  retry: () => void;
};
