import { useParams } from "react-router-dom";
import {
  useGetMeetingItemAttachmentsQuery,
  useLazyGetMeetingItemAttachmentContentQuery,
} from "../../api/propositionApi";
import { useEffect, useMemo, useState } from "react";

export default function useInitialMeetingItemAttachments(
  skip: boolean,
  propositionId?: string,
) {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
    agendaItemId = "",
  } = useParams();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [fetchAttachmentData] = useLazyGetMeetingItemAttachmentContentQuery();

  const { data } = useGetMeetingItemAttachmentsQuery(
    {
      customerToken,
      facilityObjectId,
      meetingId,
      agendaItemId,
      propositionId,
    },
    { skip },
  );

  const initialItems = useMemo(() => {
    if (data) {
      return data;
    }
    return [];
  }, [data]);

  useEffect(() => {
    const getAttachmentData = async () => {
      const fileData = data
        ? await Promise.all(
            data.map(async (item) => {
              const response = await fetchAttachmentData({
                customerToken,
                meetingId,
                facilityObjectId,
                agendaItemId,
                propositionId,
                attachmentId: item.attachmentId,
              });

              console.log("Response", response);
              if (response.data) {
                return new File([response.data], item?.originalFileName || "", {
                  type: item.contentType,
                  lastModified: new Date(item.createdAtUtc).getTime(),
                });
              }
              return null;
            }),
          )
        : [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setAttachments(fileData.filter((f) => f !== null));
    };

    getAttachmentData();
  }, [data]);

  return {
    initialItems,
    initialAttachmentValues: attachments,
  };
}
